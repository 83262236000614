@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: "inter";
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hjp-Ek-_EeA.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "inter";
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hjp-Ek-_EeA.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'polysansMedian';
  font-display: swap;
  src:
    url('/assets/common/extras/fonts/PolySans-Median.woff') format('woff'),
    url('/assets/common/extras/fonts/PolySans-Median.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'polysansMedianItalic';
  font-display: swap;
  src:
    url('/assets/common/extras/fonts/PolySans-MedianItalic.woff') format('woff'),
    url('/assets/common/extras/fonts/PolySans-MedianItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: 'italic';
}

@font-face {
  font-family: 'polysansNeutral';
  font-display: swap;
  src:
    url('/assets/common/extras/fonts/PolySans-Neutral.woff') format('woff'),
    url('/assets/common/extras/fonts/PolySans-Neutral.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* Begin Custom Styles */
@layer base {
  * {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
    font-family: 'inter', Arial, Helvetica, sans-serif;
  }


  h1 {
    font-family: 'polysansMedian', Arial, Helvetica, sans-serif;
  }

  /**
   * Transitional Tailwind Preflight styles
   * Selective styles from Tailwind Preflight base styles (https://tailwindcss.com/docs/preflight)
   * TODO: remove when Preflight is enabled in tailwind.config
   */
  button {
    background-color: transparent;
    background-image: none;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  /* //Below styles added when removing bootstrap */

  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -.5em;
  }

  ul, ol {
    margin-top: 0;
    margin-bottom: 11px;
  }

  button, html input[type="button"], input[type="reset"], input[type="submit"] {
    cursor: pointer;
  }

  button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  img {
    vertical-align: middle;
    border: 0;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 22px;
    margin-bottom: 11px;
    line-height: 1.4;
  }
  .h1, h1 {
    font-size: 44px;
  }
  .h2, h2 {
    font-size: 32px;
  }
  .h3, h3 {
    font-size: 24px;
  }
  .h4, h4 {
    font-size: 20px;
  }
  .h5, h5 {
    font-size: 16px;
  }
  p {
    margin: 0 0 11px;
  }

  /* // Override defaults styles for builder-io */
  .builder-text {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  .builder-text  > h1,.builder-text > h2,.builder-text > h3,.builder-text > h4,.builder-text > h5,.builder-text > h6,.builder-text > p {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      margin: 0;
    }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
  }

  audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
  }

  /* Alert styles */
  .alert {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    margin-bottom: 22px;
    padding: 15px;
  }
  .alert-danger {
    background-color: #f9e2e2;
    border-color: #eba3ad;
    color: #d9534f;
  }
  .alert-success {
    background-color: #d8eed8;
    border-color: #acd7a3;
    color: #5cb85c;
  }

  .alert-dismissable, .alert-dismissible {
    padding-right: 35px;
  }

  .alert-dismissable .close, .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
  button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
  }
  .close {
    opacity: 1;
  }

  /* End Tailwind Preflight */
  
}
